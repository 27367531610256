/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "../../request.js";
import { getInterceptor as t } from "../urlUtils.js";
const a = {
  async request(a, s) {
    const r = a.options,
      n = r.responseType;
    r.signal = s?.signal, r.responseType = "native" === n || "native-request-init" === n ? "native-request-init" : n && ["blob", "json", "text"].includes(n) && t(a.url)?.after ? n : "array-buffer";
    const i = await e(a.url, r),
      o = {
        data: i.data,
        httpStatus: i.httpStatus,
        ssl: i.ssl
      };
    switch (i.requestOptions?.responseType) {
      case "native-request-init":
        return delete o.data.signal, o;
      case "blob":
        o.data = await o.data.arrayBuffer();
        break;
      case "json":
        o.data = new TextEncoder().encode(JSON.stringify(o.data)).buffer;
        break;
      case "text":
        o.data = new TextEncoder().encode(o.data).buffer;
    }
    return {
      result: o,
      transferList: [o.data]
    };
  }
};
export { a as staticWorkerMessages };