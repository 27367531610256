/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import "../../config.js";
import "../../request.js";
import "../Error.js";
import "../Logger.js";
import "../urlUtils.js";
import "../has.js";
let s = null;
const a = {};
function r(s) {
  const r = {
    async: s.async,
    isDebug: s.isDebug,
    locale: s.locale,
    baseUrl: s.baseUrl,
    has: {
      ...s.has
    },
    map: {
      ...s.map
    },
    packages: s.packages?.concat() || [],
    paths: {
      ...a.paths,
      ...s.paths
    }
  };
  return s.hasOwnProperty("async") || (r.async = !0), s.hasOwnProperty("isDebug") || (r.isDebug = !1), s.baseUrl || (r.baseUrl = a.baseUrl), r;
}
export { s as defaultLoaderUrl, r as loaderConfig };